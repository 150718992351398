import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { InventoryLandingComponent } from './inventory-landing/inventory-landing.component';
import { InventoryCommonComponent } from './inventory-common/inventory-common.component';


const routes: Routes = [
  {
    path: '',
    component: InventoryCommonComponent,
    children: [
      {
        path: 'map',
        component: InventoryLandingComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class InventoryManagementRoutingModule { }
