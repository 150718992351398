import { Injectable } from '@angular/core';
import { SectionDataModel, SeatDataModel } from 'src/app/inventory-management/shared';
import { Subject, Observable } from 'rxjs';
import { DvmService } from 'src/app/shared/services/dvm.service';
import { PricingService } from '.';

@Injectable({
  providedIn: 'root'
})
export class FlowControlService {

  public availabilityBlockmap: { [key: string]: SectionDataModel } = {};
  public availabilitySeatmap: { [key: string]: SeatDataModel } = {};
  public sectionSelected: string;
  public planSelected: number;
  public flowStep: 'blockmap' | 'seatmap';
  private sectionData$ = new Subject<SectionDataModel>();
  private seatData$ = new Subject<SeatDataModel>();
  public isGa: boolean;
  public seatHash;
  public viewerMode: 'customer' | 'inventory';
  public colorsmode: 'inventory' | 'pricing';

  constructor(private dvmService: DvmService,
              private pricingService: PricingService,) {
  }

  // tslint:disable-next-line: variable-name
  setSectionData(_sectionData: SectionDataModel) {
    this.sectionData$.next(_sectionData);
  }

  getSectionData(): Observable<SectionDataModel> {
    return this.sectionData$.asObservable();
  }

  // tslint:disable-next-line:variable-name
  setSeatData(_seatData: SeatDataModel): void {
    this.seatData$.next(_seatData);
  }

  getSeatData(): Observable<any> {
    return this.seatData$.asObservable();
  }


  renewHash(): void {
    const currentSelection = this.dvmService.viewer.getNodesByState('seat', 'selected');
    for (const seat of currentSelection) {
      if (!this.seatHash[seat.id]) {
        this.seatHash[seat.id] = true;
      }
    }
    this.pricingService.seatsSelectedLengh.next(this.dvmService.viewer.getNodesByState('seat', 'selected').length);
  }


}
