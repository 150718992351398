import { Component, OnInit } from '@angular/core';
import { MetricService } from '../charts/metric.service';
import { ChartsService } from '../charts';
import { InventoryService } from '../inventory-management/services';
import { PlanModel } from '../shared';

@Component({
  selector: 'app-metrics',
  templateUrl: './metrics.component.html',
  styleUrls: ['./metrics.component.scss']
})
export class MetricsComponent implements OnInit {

  premiumPlan: PlanModel;

  addonsPerPriceType;
  addonsPerPriceTypeData;

  releasesPerPriceType;
  releasesPerPriceTypeData;

  keepsPerPriceType;
  keepsPerPriceTypeData;

  deviceCategory;
  deviceCategoryData;

  addonsBalance;
  addonsBalanceData;

  verticalBarChartBaseConfig;
  pieChartBaseConfig;
  advancedPieChartBaseConfig;

  totalRevenueBySaleData;
  totalRevenueBySaleConfig;

  constructor(private metricService: MetricService,
              private inventoryService: InventoryService,
              private chartService: ChartsService) { }

  ngOnInit() {
    this.inventoryService.getPlans().subscribe((planList) => {
      this.premiumPlan = planList.filter((plan) => plan.name === 'Full' )[0];
      this.setAddonsBalance();
      this.setDeviceCategoryMetric();
      this.setAddonsPerPriceType();
      this.setReleasesPerPriceType();
      this.setKeepPerPriceType();
      this.setTotalRevenueBySale();
    });
  }

  // Loads metric addons balance and set values
  private setAddonsBalance(): void {
    this.metricService.getTotalBalance().subscribe(
      response => {
        this.addonsBalance = response;
        this.addonsBalanceData = this.chartService.parseAddonsPriceType(this.addonsBalance);
        this.advancedPieChartBaseConfig = {
          view: [],
          results: this.addonsBalanceData,
          animations: true,
          labels: true,
          legend: true,
          legendPosition: 'below',
          legendTitle: 'Balance',
          doughnut: false,
          gradient: false,
          scheme: {
            // domain: ['#003399', '#FF9900', '#CC3333', '#66CC99', '#FF9999']
            domain: ['#081b77', '#0a208f', '#0c25a7', '#0d2bbf', '#0f30d7', '#1136ee', '#284af0', '#405ef2',
              '#5872f3', '#7086f5', '#889af7']
          }
        };
      }
    );
  }
  // Loads metric devices and set values
  private setDeviceCategoryMetric(): void {
    this.metricService.getDeviceCategory().subscribe(
      deviceCategory => {
        this.deviceCategory = deviceCategory;
        this.deviceCategoryData = this.chartService.parseData(this.deviceCategory);
        this.pieChartBaseConfig = {
          view: [],
          results: this.deviceCategoryData,
          animations: true,
          labels: true,
          legend: false,
          legendPosition: 'below',
          legendTitle: 'Platform used',
          doughnut: false,
          gradient: false,
          scheme: {
            // domain: ['#003399', '#FF9900', '#CC3333', '#66CC99', '#FF9999']
            domain: ['#081b77', '#0a208f', '#0c25a7', '#0d2bbf', '#0f30d7', '#1136ee', '#284af0', '#405ef2',
              '#5872f3', '#7086f5', '#889af7']
          }
        };
      }
    );
  }

  // Loads metric addons per price type and set values
  private setAddonsPerPriceType(): void {
    this.metricService.getAddonsPerPriceType(this.premiumPlan.id).subscribe(
      response => {
        this.addonsPerPriceType = response;
        this.addonsPerPriceTypeData = this.chartService.parseReleasePriceType(this.addonsPerPriceType);
        this.verticalBarChartBaseConfig = {
          view: [300, 0],
          results: this.addonsPerPriceTypeData,
          scheme: {
            // domain: ['#003399', '#FF9900', '#CC3333', '#66CC99', '#FF9999']
            domain: ['#081b77', '#0a208f', '#0c25a7', '#0d2bbf', '#0f30d7', '#1136ee', '#284af0', '#405ef2',
              '#5872f3', '#7086f5', '#889af7']
          },
          animations: true,
          labels: false,
          gradient: false,
          legend: false,
          legendTitle: 'defautLegend',
          xAxisLabel: 'defaultX',
          yAxisLabel: 'defaultY',
          roundEdges: true
        };
      }
    );
  }

  // loads metric releases per price type and set values
  private setReleasesPerPriceType(): void {
    this.metricService.getReleasesPerPriceType(this.premiumPlan.id).subscribe(
      response => {
        this.releasesPerPriceType = response;
        this.releasesPerPriceTypeData = this.chartService.parseReleasePriceType(this.releasesPerPriceType);
        this.verticalBarChartBaseConfig = {
          view: [300, 0],
          results: this.releasesPerPriceTypeData,
          scheme: {
            // domain: ['#003399', '#FF9900', '#CC3333', '#66CC99', '#FF9999']
            domain: ['#081b77', '#0a208f', '#0c25a7', '#0d2bbf', '#0f30d7', '#1136ee', '#284af0', '#405ef2',
              '#5872f3', '#7086f5', '#889af7']
          },
          animations: true,
          labels: false,
          gradient: false,
          legend: false,
          legendTitle: 'defautLegend',
          xAxisLabel: 'defaultX',
          yAxisLabel: 'defaultY',
          roundEdges: true
        };
      }
    );
  }

  private setKeepPerPriceType(): void {
    this.metricService.getKeptPerPriceType(this.premiumPlan.id).subscribe(
      response => {
        this.keepsPerPriceType = response;
        this.keepsPerPriceTypeData = this.chartService.parseReleasePriceType(this.keepsPerPriceType);
        this.verticalBarChartBaseConfig = {
          view: [300, 0],
          results: this.keepsPerPriceTypeData,
          scheme: {
            // domain: ['#003399', '#FF9900', '#CC3333', '#66CC99', '#FF9999']
            domain: ['#081b77', '#0a208f', '#0c25a7', '#0d2bbf', '#0f30d7', '#1136ee', '#284af0', '#405ef2',
              '#5872f3', '#7086f5', '#889af7']
          },
          animations: true,
          labels: false,
          gradient: false,
          legend: false,
          legendTitle: 'defautLegend',
          xAxisLabel: 'defaultX',
          yAxisLabel: 'defaultY',
          roundEdges: true
        };
      }
    );
  }
  private setTotalRevenueBySale(): void {
    this.metricService.getTotalRevenueBySaleType().subscribe(
      totalRevenueBySale => {
        this.totalRevenueBySaleData = totalRevenueBySale;
        this.totalRevenueBySaleConfig = {
          view: [],
          results: this.chartService.parseData(this.totalRevenueBySaleData),
          animations: true,
          labels: true,
          legend: false,
          legendPosition: 'below',
          legendTitle: 'Total Revenue Gain',
          doughnut: false,
          gradient: false,
          scheme: {
            // domain: ['#003399', '#FF9900', '#CC3333', '#66CC99', '#FF9999']
            domain: ['#081b77', '#0a208f', '#0c25a7', '#0d2bbf', '#0f30d7', '#1136ee', '#284af0', '#405ef2',
              '#5872f3', '#7086f5', '#889af7']
          }
        };
      }
    );
  }

}
