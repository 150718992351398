import { Component, Inject, Input, OnInit } from '@angular/core';
import { UserService } from '../services';
import { CommonService } from '../../services';
import { UserFilterModel, UserModel } from '../shared/models';
import { saveAs } from 'file-saver';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap';
// import { UserFormModalComponent } from '../user-form-modal';
import { PaginatedResponseModel } from '../../shared/models';
import { NgForm } from '@angular/forms';
import { MessagingService } from 'src/app/shared';
import { UserFormModalComponent } from '../user-form-modal';
import { APP_CONFIG, BackofficeConfigurationModel } from '../../configuration';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html'
})
export class UserListComponent implements OnInit {
  @Input() userLogged;
  userList: PaginatedResponseModel<UserModel>;
  filter: UserFilterModel = {} as UserFilterModel;
  bsModalRef: BsModalRef;
  // Pagination variables
  totalItems: number;
  itemsPerPage: number;
  currentPage: number;

  // tslint:disable-next-line:variable-name
  constructor(@Inject(APP_CONFIG) public app_config: BackofficeConfigurationModel,
              private userService: UserService, private modalService: BsModalService,
              private commonService: CommonService, private messageService: MessagingService) {
    this.currentPage = 1;
    this.itemsPerPage = 10; // Same number in API Settings.
    this.userList = {} as PaginatedResponseModel<UserModel>;
  }

  ngOnInit() {
    this.filter.is_active = '';
    this.filter.type = '';
    this.init();
  }

  init(): void {
    this.userService.getUsers(this.currentPage).subscribe(
      data => {
        this.userList = data;
        this.totalItems = this.userList.count;
      });
  }

  pageChanged(event) {
    this.currentPage = event.page;

    if (this.commonService.filterisEmpty(this.filter)) {
      this.init();
    } else {
      this.applyFilter();
    }
  }

  onSubmitFilter(): void {
    this.currentPage = 1;
    this.applyFilter();
  }

  applyFilter(): void {
    if (this.filter.is_active !== '') {
      this.filter.is_active = JSON.parse(this.filter.is_active);
    }
    this.userService.getFilteredUsers(this.filter, this.currentPage).subscribe(
      data => {
        this.userList = data;
        this.totalItems = this.userList.count;
      });
  }

  exportCustomerSeatsCSV(): void {
    this.userService.getCustomerPlanSeatsCSV(this.filter).subscribe(
      data => {
        const date = new Date().toLocaleString();
        saveAs(data, `seats_${date}.csv`);
      }
    );
  }

  exportCustomerPlansCSV(): void {
    this.userService.getCustomerPlansCSV(this.filter).subscribe(
      data => {
        const date = new Date().toLocaleString();
        saveAs(data, `users_${date}.csv`);
      }
    );
  }

  onOpenRegisterModal(): void {
    const modalConfig: ModalOptions = {
      animated: true,
      class: 'modal-dialog-centered',
      backdrop: true,
      ignoreBackdropClick: true,
      initialState: {}
    };
    const initialState = {};
    this.bsModalRef = this.modalService.show(UserFormModalComponent, modalConfig);

  }

  restartForm(form: NgForm): void {
    this.currentPage = 1;
    this.filter = {
      username: '',
      first_name: '',
      last_name: '',
      is_active: '',
      type: '',
      account_name: '',
      last_login_after: '',
      last_login_before: ''
    };
    form.form.markAsUntouched();
    form.form.markAsPristine();
    this.init();
  }

  private onExportCustomerSeats(): void {
    const title = 'Export';
    const message = 'Do you really want to export the data?';
    const acceptBtnName = 'Accept';
    const closeBtnName = 'Close';
    this.messageService.info(title, message, acceptBtnName, () => { this.exportCustomerSeatsCSV(); }, closeBtnName);
  }

  private onExportCustomerPlans(): void {
    const title = 'Export';
    const message = 'Do you really want to export the data?';
    const acceptBtnName = 'Accept';
    const closeBtnName = 'Close';
    this.messageService.info(title, message, acceptBtnName, () => { this.exportCustomerPlansCSV(); }, closeBtnName);
  }
}
