import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { PlanModel } from '../../shared/models';
import { PriceTypeModel } from 'src/app/shared/models/price-type.model';
import { NewPriceTypePriceModel } from '../shared/models';

@Injectable({
  providedIn: 'root'
})
export class PricingService {

  seatsSelected: Array<any> = [];
  seatsSelectedLengh = new Subject<number>();

  constructor(private http: HttpClient) {
  }

  getPlanAvailability(planId: number): Observable<any> { // TODO Create Model
    return this.http.get<any>(`/plan/${planId}/inventory/`);
  }

  getSectionAvailability(planId: number, sectionId: string): Observable<any> { // TODO Create Model
    return this.http.get<any>(`/plan/${planId}/inventory/${sectionId}`);
  }

  getSectionSeatPriceType(sectionId: string): Observable<any> {
    return this.http.get<any>(`/section_seat_price_type/${sectionId}`);
  }

  getPlans(): Observable<Array<PlanModel>> {
    return this.http.get<Array<PlanModel>>(`/get_plans_readonly/`);
  }

  getSeatSelectedLength(): Observable<number> {
    return this.seatsSelectedLengh.asObservable();
  }

  setSeatSelectedLength(length: number): void {
    this.seatsSelectedLengh.next(length);
  }

  getPriceTypes(): Observable<Array<PriceTypeModel>> {
    return this.http.get<Array<PriceTypeModel>>(`/price_type_readonly/`);
  }

  setSeatPriceType(priceTypeId: number, seatList: Array<string>): Observable<any> {
    return this.http.post(`/price_type/${priceTypeId}/`, {seats: seatList});
  }

  // Se prepara un hashmap para insertar la disponibilidad
  getSeatmapAvailability(seatmaparray: any): any {
    const availability = {};
    for (const obj of seatmaparray) {
      availability[obj.id] = obj;
    }
    return availability;
  }

  getPriceTable(): Observable<any> {
    return this.http.get<any>(`/prices/`);
  }

  postNewPriceTypePrice(newPriceTypePirce: NewPriceTypePriceModel): Observable<any> {
    return this.http.post(`/price_type_price_manage/`, newPriceTypePirce);
  }

  postNewPriceType(pricetypename): Observable<any> {
    return this.http.post(`/create_price_type/`, pricetypename);
  }

  patchPriceType(id, name): Observable<any> {
    return this.http.patch(`/price_type_view/${id}/`, {name});
  }

  patchPriceTypePrice(id: number, price: string): Observable<any> {
    return this.http.patch(`/price_type_price_manage/${id}/`, {
      id,
      price
    });
  }

  deletePriceTypePrice(id: number): Observable<any> {
    return this.http.delete(`/price_type_price_manage/${id}/`);
  }

  deletePriceType(id: number): Observable<any> {
    return this.http.delete(`/price_type_view/${id}/`);
  }
}
