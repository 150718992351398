import { Component, Inject, OnInit } from '@angular/core';
import { DjangoSessionAuthenticationService } from '../../auth';
import { Router } from '@angular/router';
import { BackofficeUser } from '../../shared/models';
import { APP_CONFIG } from '../../configuration';
import { BackofficeConfigurationModel } from '../../configuration';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  logo: string;
  user$: BackofficeUser | boolean;

  // tslint:disable-next-line:variable-name
  constructor(@Inject(APP_CONFIG) public app_config: BackofficeConfigurationModel,
              private auth: DjangoSessionAuthenticationService, private router: Router) {
  }

  ngOnInit() {
    this.auth.getUserLogged$().subscribe(
      (response) => {
        this.user$ = response;
      }
    );
  }

  logout(): void {
    this.auth.logout().subscribe(() => this.router.navigate(['/login']));
  }
}
