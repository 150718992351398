import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {DjangoSessionAuthenticationService} from '../../auth';
import {BackofficeUser} from '../../shared/models';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent implements OnInit {
  open: boolean;
  userLogged: BackofficeUser;

  constructor(private route: ActivatedRoute, private auth: DjangoSessionAuthenticationService) {
    this.open = true;
    this.userLogged = {type: 'sales'} as BackofficeUser;
  }

  ngOnInit() {
    this.auth.getUserLogged$().subscribe((data) => {
      if (typeof data !== 'boolean') {
        this.userLogged = data;
      }
    });
  }

  onClickBurger(): void {
    if (this.open) {
      document.getElementById('burguercont').classList.add('burg');
      document.getElementsByClassName('sideMenu').item(0).classList.add('open');
      const listNode = document.getElementsByClassName('menuText');
      for (let i = 0; i < listNode.length; i++) {
        listNode.item(i).classList.add('textOpen');
      }
      const nodeList = document.getElementsByClassName('pos') as HTMLCollectionOf<HTMLElement>;
      for (const node of nodeList as any) {
        node.style.left = '-47.5%';
      }
      const sideMenu = document.getElementsByClassName('sideMenu').item(0) as HTMLElement;
    } else { // close side menu
      document.getElementById('burguercont').classList.remove('burg');
      document.getElementsByClassName('sideMenu').item(0).classList.remove('open');
      const listNode = document.getElementsByClassName('menuText');
      for (let i = 0; i < listNode.length; i++) {
        listNode.item(i).classList.remove('textOpen');
      }
      const nodeList = document.getElementsByClassName('pos') as HTMLCollectionOf<HTMLElement>;
      for (const node of nodeList as any) {
        node.style.left = '-44.5%';
      }
      const sideMenu = document.getElementsByClassName('sideMenu').item(0) as HTMLElement;
    }

    this.open = !this.open;
  }

}
