import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CustomerPlanModel, PlanModel } from '../../shared/models';
import { Observable } from 'rxjs';
import { UserModel } from '../shared/models';
import { UserRegisterModel } from '../shared/models/user-register.model';
import { DateService } from '../../services';
import { SeatDataModel } from '../../pricing-management/shared/models/seat-data.model';

@Injectable({
  providedIn: 'root'
})
export class RegisterService {

  constructor(private http: HttpClient, private dateFactory: DateService) {
  }

  getPlans(): Observable<Array<PlanModel>> {
    return this.http.get<Array<PlanModel>>(`/get_plans_readonly/`);
  }

  postCustomerRegistration(element: UserRegisterModel | UserModel): Observable<any> {
    return this.http.post(`/customer_registration/`, element);
  }

  getPlanAvailability(plan: number): Observable<Array<any>> {
    return this.http.get<Array<any>>(`/plan/${plan}/availability/`);
  }

  getPlanAvailabilitySection(plan: number, section: string): Observable<any> {
    return this.http.get(`/plan/${plan}/availability/${section}/`);
  }

  postCreateCustomerPlan(customerplan: CustomerPlanModel): Observable<any> {
    const list = [];
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < customerplan.seatList.length; i++) {
      list.push(customerplan.seatList[i].id);
    }
    // tslint:disable-next-line:max-line-length
    customerplan.appointment_date_from = this.dateFactory.transformFormDate(customerplan.appointment_date_from_date, customerplan.appointment_time_from_date);
    // tslint:disable-next-line:max-line-length
    customerplan.appointment_date_to = this.dateFactory.transformFormDate(customerplan.appointment_date_to_date, customerplan.appointment_time_to_date);
    return this.http.post(`/customer_plan_create/`,
      {
        addons_allowed: customerplan.addons_allowed,
        appointment_date_from: customerplan.appointment_date_from,
        appointment_date_to: customerplan.appointment_date_to,
        plan: customerplan.plan.id,
        customer: customerplan.user.id,
        new_seats: list
      });
  }

  postAddSeat(payload: {customer_plan: number, seat: any}): Observable<any> {
    return this.http.post(`/customer_plan_seat_add_delete/`, payload);
  }

  getReleaseSeats(seat: string, plan: number): Observable<any> {
    const body = {
      seats: [seat]
    };
    return this.http.post(`/plan/${plan}/seat/release/`, body);
  }

  postReserveSeats(seats: Array<string>, plan: number, customer: number): Observable<any> {
    const body = {
      seats,
      customer
    };
    return this.http.post(`/plan/${plan}/seat/reserve/`, body);
  }
}
