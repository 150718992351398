import { Component, Input, OnInit } from '@angular/core';
import { UserService } from '../services';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap';
import { SalesRepModel, UserModel } from '../shared/models';
import { ValidateFormService } from '../../services';
import { Observable, Subject } from 'rxjs';
import { NgForm } from '@angular/forms';
import { BackofficeUser, CustomerPlanModel, PlanModel } from '../../shared/models';
import { DjangoSessionAuthenticationService } from '../../auth';
import { CustomerPlanModalComponent } from '../customer-plan-modal';
import { MessagingService } from 'src/app/shared';
import { NotificationService } from 'src/app/services/notification.service';
import { environment } from '../../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { InventoryService } from '../../inventory-management/services';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html'
})
export class UserDetailsComponent implements OnInit {

  id: any;
  user: UserModel;
  userLogged: BackofficeUser;
  defaultUser: UserModel;
  bsModalRef: BsModalRef;
  repList: Observable<Array<SalesRepModel>>;
  reservedSeatList: Array<any>;

  // Accordion
  accordionHash = {};
  accordionToggleSubject = new Subject<number>();
  accordionHashSubject = new Subject();

  constructor(private userService: UserService,
              private modalService: BsModalService,
              public formValidatorService: ValidateFormService,
              private auth: DjangoSessionAuthenticationService,
              private messageService: MessagingService,
              private inventoryService: InventoryService,
              private notificationService: NotificationService,
              private route: ActivatedRoute,
              private router: Router) {
    this.defaultUser = {} as UserModel;
    this.reservedSeatList = [];
  }

  ngOnInit() {
    this.init();
    this.auth.getUserLogged$().subscribe((data) => {
      if (typeof data !== 'boolean') {
        this.userLogged = data;
      }
    });
  }

  private init() {
    this.id = this.route.snapshot.paramMap.get('id');
    // Get customer details
    this.userService.getUserDetails(this.id).subscribe(
      response => {
        this.user = response;
        // Get all customer plans from User ID
        this.userService.getCustomerPlan(this.user.id).subscribe(
          data => {
            this.user.customerplan_list = data;
            this.defaultUser = JSON.parse(JSON.stringify(this.user));
          }
        );
        this.inventoryService.getPlans().subscribe((planList) => {
          this.userService.getReservedSeats(this.user.id).subscribe(
            reservedSeatList => {
              const findPlan = (name: string, list: PlanModel[]) => {
                return list.filter((plan) =>  name === plan.name);
              };
              for (const rseat of reservedSeatList) {
                rseat.planObj = findPlan(rseat.plan, planList)[0];
              }
              this.reservedSeatList = reservedSeatList;
              console.log('Reserved seats: ', this.reservedSeatList);
            }
          );
        });
      }
    );
    this.repList = this.userService.getSalesRep();
    // subject subscribed that calls the function onToggleAccordion when nexts
    this.accordionToggleSubject.subscribe(
      accordionId => {
        this.onToggleAccordion(accordionId);
      }
    );
  }

  onToggleAccordion(id: number) {
    // loops the accordion checking if the key is equal to the id
    for (const [key, value] of Object.entries(this.accordionHash)) {
      if (parseInt(key, 10) === id) {
        continue;
      }
      this.accordionHash[key] = false;
    }
    this.accordionHash[id] = !this.accordionHash[id];
    this.accordionHashSubject.next(this.accordionHash);
  }

  onSubmit(form: NgForm): void {
    if (form.invalid) {
      return;
    }
    this.user = JSON.parse(JSON.stringify(this.defaultUser));
    this.userService.patchUserDetails(this.defaultUser).subscribe(
      response => {
        this.notificationService.showSuccess('Customer modified');
        this.user = JSON.parse(JSON.stringify(response));
      },
      error => {
        this.notificationService.showError('Could not modify customer');
      }
    );
  }

  onTakeOver(): void {
    const title = 'Warning';
    const message = 'You will now be redirected to the customer portal and take over this customer’s\
     transaction so you can perform operations on their behalf. Are you sure you want to continue?';
    const acceptBtnName = 'Accept';
    const closeBtnName = 'Close';
    this.messageService.warning(title, message, acceptBtnName, () => {
      this.takeOver();
    }, closeBtnName);
  }

  takeOver(): void {
    this.userService.takeOverRequest({customer_id: this.user.id, admin_id: this.userLogged.id}).subscribe(
      data => {
        window.open(environment.takeOverUrl, '_self');
      },
      error => {
        console.log('Error');
      }
    );
  }

  onNewPlan(): void {
    const modalConfig: ModalOptions = {
      animated: true,
      class: 'modal-dialog-centered',
      backdrop: true,
      ignoreBackdropClick: true,
      initialState: {
        user: this.user
      }
    };
    this.bsModalRef = this.modalService.show(CustomerPlanModalComponent, modalConfig);
    const subscription = this.modalService.onHide.subscribe((reason: string) => {
      subscription.unsubscribe();
      if (this.bsModalRef.content.closeReason === 'add') {
        if (!this.defaultUser.hasOwnProperty('customerplan_list')) {
          this.defaultUser.customerplan_list = [];
        }
        // this.stateService.go(this.stateService.current, {id: this.defaultUser.id}, {reload: true});
        // TODO REFRESH WINDOW WHEN NEW PLAN
        this.ngOnInit();
      }
    });
  }

  objtoseat(cp: CustomerPlanModel, list: Array<{ id: string, section: string, row: string, seat: string }>): CustomerPlanModel {
    cp.seats = [];
    for (const seat of list) {
      if (typeof seat === 'undefined') {
        continue;
      }
      cp.seats.push(seat.id);
    }
    return cp;
  }
}
