import { DVMConfiguration } from './dvm-configuration.model';
import { InjectionToken } from '@angular/core';

export const DVM_CONFIG = new InjectionToken<DVMConfiguration>('DVM Configuration');
export const DVMConfig: DVMConfiguration = {
    venue_id: 'nam-us-00010-lagalaxy',
    map_id: 'blockmap',
    container: 'viewer-container',
    version: 'development',
    plugins: ['selection_area'],
    new_styles: true,
};
