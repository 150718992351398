import { InjectionToken } from '@angular/core';
import { BackofficeConfigurationModel } from './backoffice-configuration.model';

export const APP_CONFIG = new InjectionToken<BackofficeConfigurationModel>('BackofficeConfiguration');
export const ApplicationConfiguration: BackofficeConfigurationModel = {
  header: {
    text: 'Backoffice - Seating Relocation 2023/2024',
    logo: {
      image: '../assets/images/logo.png',
      alt: 'Team Logo Backoffice'
    }
  },
  transactions: {
    columns: {
      first_name: false,
      last_name: false,
      username: true,
      account_name: true,
      upgrade: false,
      email: false,
      sales_rep: true
    },
    filters: {
      first_name: false,
      username: true,
      account_name: true,
      season: false,
      last_name: false,
      plan: true,
    }
  },
  users: {
    columns: {
      account_name: true,
      first_name: false,
      last_name: false,
      last_login: true,
    },
    filters: {
      username: true,
      account_name: true,
      first_name: false,
      last_name: false,
      user_type: false
    }
  },
  suitesHash: {
    'S_A1(INT)': true,
    'S_A10(INT)': true,
    'S_A11(INT)': true,
    'S_A12(INT)': true,
    'S_A13(INT)': true,
    'S_A14(INT)': true,
    'S_A15(INT)': true,
    'S_A16(INT)': true,
    'S_A17(INT)': true,
    'S_A18(INT)': true,
    'S_A19(INT)': true,
    'S_A2(INT)': true,
    'S_A20(INT)': true,
    'S_A21(INT)': true,
    'S_A22(INT)': true,
    'S_A23(INT)': true,
    'S_A24(INT)': true,
    'S_A25(INT)': true,
    'S_A26(INT)': true,
    'S_A27(INT)': true,
    'S_A28(INT)': true,
    'S_A3(INT)': true,
    'S_A30(INT)': true,
    'S_A31(INT)': true,
    'S_A32(INT)': true,
    'S_A33(INT)': true,
    'S_A34(INT)': true,
    'S_A35(INT)': true,
    'S_A36(INT)': true,
    'S_A37(INT)': true,
    'S_A38(INT)': true,
    'S_A39(INT)': true,
    'S_A4(INT)': true,
    'S_A40(INT)': true,
    'S_A41(INT)': true,
    'S_A42(INT)': true,
    'S_A43(INT)': true,
    'S_A44(INT)': true,
    'S_A45(INT)': true,
    'S_A46(INT)': true,
    'S_A47(INT)': true,
    'S_A48(INT)': true,
    'S_A49(INT)': true,
    'S_A5(INT)': true,
    'S_A50(INT)': true,
    'S_A51(INT)': true,
    'S_A52(INT)': true,
    'S_A53(INT)': true,
    'S_A54(INT)': true,
    'S_A55(INT)': true,
    'S_A56(INT)': true,
    'S_A6(INT)': true,
    'S_A7(INT)': true,
    'S_A8(INT)': true,
    'S_A9(INT)': true,
    'S_B1(INT)': true,
    'S_B10(INT)': true,
    'S_B11(INT)': true,
    'S_B12(INT)': true,
    'S_B13(INT)': true,
    'S_B14(INT)': true,
    'S_B15(INT)': true,
    'S_B16(INT)': true,
    'S_B17(INT)': true,
    'S_B18(INT)': true,
    'S_B19(INT)': true,
    'S_B2(INT)': true,
    'S_B20(INT)': true,
    'S_B21(INT)': true,
    'S_B22(INT)': true,
    'S_B23(INT)': true,
    'S_B24(INT)': true,
    'S_B25(INT)': true,
    'S_B26(INT)': true,
    'S_B27(INT)': true,
    'S_B28(INT)': true,
    'S_B29(INT)': true,
    'S_B3(INT)': true,
    'S_B30(INT)': true,
    'S_B32(INT)': true,
    'S_B33(INT)': true,
    'S_B34(INT)': true,
    'S_B35(INT)': true,
    'S_B36(INT)': true,
    'S_B37(INT)': true,
    'S_B38(INT)': true,
    'S_B39(INT)': true,
    'S_B4(INT)': true,
    'S_B40(INT)': true,
    'S_B41(INT)': true,
    'S_B42(INT)': true,
    'S_B43(INT)': true,
    'S_B44(INT)': true,
    'S_B45(INT)': true,
    'S_B46(INT)': true,
    'S_B47(INT)': true,
    'S_B48(INT)': true,
    'S_B49(INT)': true,
    'S_B5(INT)': true,
    'S_B50(INT)': true,
    'S_B51(INT)': true,
    'S_B52(INT)': true,
    'S_B53(INT)': true,
    'S_B54(INT)': true,
    'S_B55(INT)': true,
    'S_B56(INT)': true,
    'S_B57(INT)': true,
    'S_B58(INT)': true,
    'S_B59(INT)': true,
    'S_B6(INT)': true,
    'S_B60(INT)': true,
    'S_B61(INT)': true,
    'S_B62(INT)': true,
    'S_B7(INT)': true,
    'S_B8(INT)': true,
    'S_B9(INT)': true,
    'S_C1(INT)': true,
    'S_C10(INT)': true,
    'S_C11(INT)': true,
    'S_C12(INT)': true,
    'S_C13(INT)': true,
    'S_C14(INT)': true,
    'S_C15(INT)': true,
    'S_C16(INT)': true,
    'S_C17(INT)': true,
    'S_C18(INT)': true,
    'S_C19(INT)': true,
    'S_C2(INT)': true,
    'S_C20(INT)': true,
    'S_C21(INT)': true,
    'S_C22(INT)': true,
    'S_C23(INT)': true,
    'S_C24(INT)': true,
    'S_C25(INT)': true,
    'S_C26(INT)': true,
    'S_C27(INT)': true,
    'S_C28(INT)': true,
    'S_C29(INT)': true,
    'S_C3(INT)': true,
    'S_C30(INT)': true,
    'S_C31(INT)': true,
    'S_C32(INT)': true,
    'S_C33(INT)': true,
    'S_C34(INT)': true,
    'S_C35(INT)': true,
    'S_C36(INT)': true,
    'S_C37(INT)': true,
    'S_C38(INT)': true,
    'S_C39(INT)': true,
    'S_C4(INT)': true,
    'S_C40(INT)': true,
    'S_C41(INT)': true,
    'S_C42(INT)': true,
    'S_C43(INT)': true,
    'S_C44(INT)': true,
    'S_C45(INT)': true,
    'S_C46(INT)': true,
    'S_C47(INT)': true,
    'S_C48(INT)': true,
    'S_C49(INT)': true,
    'S_C5(INT)': true,
    'S_C50(INT)': true,
    'S_C51(INT)': true,
    'S_C52(INT)': true,
    'S_C53(INT)': true,
    'S_C54(INT)': true,
    'S_C6(INT)': true,
    'S_C7(INT)': true,
    'S_C8(INT)': true,
    'S_C9(INT)': true,
    'S_Lexus(INT)': true,
    'S_SMC(INT)': true,
    S_TB0: true,
    S_TB1: true,
    S_TB2: true,
    S_TB3: true,
    S_TB4: true,
    S_TB5: true,
    S_TB6: true,
    S_TB7: true,
    S_TB8: true,
    S_TB9: true,
    S_TB10: true,
    S_TB11: true,
    S_TB12: true,
    S_TB13: true,
    S_TB14: true,
    S_TB15: true,
    S_TB16: true,
    S_TB17: true,
    S_TB18: true,
    S_TB19: true,
    S_TB20: true,
    S_TB21: true,
    S_TB22: true,
    S_TB23: true,
    S_TB24: true,
    S_TB25: true,
    S_TB26: true
  }
};
