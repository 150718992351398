import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SeatTransactionModel, TransactionModel } from '../shared';
import { PaginatedResponseModel, PlanModel } from '../../shared/models';

@Injectable({
  providedIn: 'root'
})
export class TransactionService {

  constructor(private http: HttpClient) {
  }

  getTransactions(page: number): Observable<PaginatedResponseModel<TransactionModel>> {
    return this.http.get<PaginatedResponseModel<TransactionModel>>(`/transaction_readonly/?page=${page}&master=true`);
  }

  getTransactionById(transactionID: number): Observable<TransactionModel> {
    return this.http.get<TransactionModel>(`/transaction_readonly/${transactionID}/`);
  }

  getFilterTransactions(filter: any, page: number): Observable<PaginatedResponseModel<TransactionModel>> {
    return this.http.get<PaginatedResponseModel<TransactionModel>>(`/transaction_readonly/?page=${page}&master=true`, {params: filter});
  }

  getTransactionCSV(params: any): any {
    const paramsNoStatus = JSON.parse(JSON.stringify(params));
    // delete paramsNoStatus['status'];
    const HTTPOptions = {
      headers: new HttpHeaders({
        Accept: 'text/csv'
      }),
      responseType: 'blob' as 'json',
      params: paramsNoStatus
    };
    return this.http.get(`/seat_transaction_export_csv/`, HTTPOptions);
  }

  getStaplesMailConfirmationCSV(params: any): any {
    const paramsNoStatus = JSON.parse(JSON.stringify(params));
    // delete paramsNoStatus['status'];
    const HTTPOptions = {
      headers: new HttpHeaders({
        Accept: 'text/csv'
      }),
      responseType: 'blob' as 'json',
      params: paramsNoStatus
    };
    return this.http.get(`/transaction/staples/export/mail_confirmation/`, HTTPOptions);
  }

  getSeatTransactions(target: number): Observable<Array<SeatTransactionModel>> {
    return this.http.get<Array<SeatTransactionModel>>(`/filtered_seattransaction/?transaction=${target}`);
  }

  statusTranslate(status: string): string {
    let instance = 'none';
    switch (status) {
      case 'PH' :
        instance = 'On Hold';
        break;
      case 'OK' :
        instance = 'OK';
        break;
      case 'PR' :
        instance = 'Reserved';
        break;
      case 'CA' :
        instance = 'Restarted';
        break;
    }

    return instance;
  }

  confirmTransaction(id: number): Observable<any> {
    return this.http.patch(`/transaction/${id}/`, {});
  }

  cancelTransaction(id: number): Observable<any> {
    return this.http.delete(`/transaction/${id}/`);
  }

  getPlans(): Observable<Array<PlanModel>> {
    return this.http.get<Array<PlanModel>>(`/get_plans_readonly/`);
  }
}

