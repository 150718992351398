import { ChangeDetectorRef, Component, ElementRef, HostListener, Inject, OnInit, ViewChild } from '@angular/core';
import { DVM_CONFIG } from '../../configuration/dvm.configuration';
import { DVMConfiguration } from '../../configuration/dvm-configuration.model';
import { APP_CONFIG, BackofficeConfigurationModel } from '../../configuration';
import { InventoryService } from '../services';
import { NgForm } from '@angular/forms';
import { InventoryListFilterModel } from '../shared';
import { PlanModel } from '../../shared';
import { Observable } from 'rxjs/';
import { saveAs } from 'file-saver';
import { InventoryListModel } from '../shared/models/inventory-list.model';
import { PageChangedEvent } from 'ngx-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '../../services/notification.service';
import { UserModel } from '../../user-management/shared';

@Component({
  selector: 'app-inventory-list',
  templateUrl: './inventory-list.component.html',
  styleUrls: ['./inventory-list.component.scss']
})
@HostListener('window:scroll', [])
export class InventoryListComponent implements OnInit {

  @ViewChild('rowSelector', {static: false}) rowSelectorElement: ElementRef;

  filter = {} as InventoryListFilterModel;
  selectedPlanId: number;
  windowScrolled: boolean;
  premiumPlan: PlanModel;
  planList: Array<PlanModel>;
  sectionList: Array<string> = [];
  paginationVisible: boolean;
  // plansObservable$: Observable<Array<PlanModel>>;
  pristineInventoryObject: {
    planId: number,
    list: Array<InventoryListModel>;
  };
  showToTop: boolean;
  inventoryList: Array<InventoryListModel>;
  inventoryListOnView: Array<InventoryListModel>;
  start = true;
  // Pagination variables
  perpage: string;
  totalItems: number;
  itemsPerPage: number;
  currentPage: number;

  constructor(@Inject(DVM_CONFIG) private dvmConfig: DVMConfiguration,
              private toastr: NotificationService,
              @Inject(APP_CONFIG) public appConfig: BackofficeConfigurationModel,
              private route: ActivatedRoute, private router: Router,
              private inventoryService: InventoryService) {
    this.currentPage = 1;
    // this.itemsPerPage = 10; // Same number in API Settings.
    this.inventoryListOnView = [];
    this.inventoryList = [];
    // this.showToTop = false;
  }

  ngOnInit() {
    this.filter.status = '';
    this.filter.section = '';
    this.paginationVisible = true;
    this.inventoryService.getPlans().subscribe((response) => {
      this.planList = response;
      this.premiumPlan = this.planList.filter((plan) => plan.name.includes('Premier') )[0];
      this.filter.planId = `0`;
      // this.init();
    });
  }

  private init(plan: number = null, status: string = null, section: string = null): void {
    const p: number = plan || this.premiumPlan.id;
    this.itemsPerPage = 10; // Same number in API Settings.
    this.showToTop = false;
    this.paginationVisible = true;
    if (this.rowSelectorElement) {
      this.rowSelectorElement.nativeElement.value = '10';
    }
    this.inventoryService.getInventoryPlanList(p).subscribe((list: Array<InventoryListModel>) => {
      const cleanList = this.inventoryService.removeSeatFromList(['Lexus Club', 'Lot C', 'Lot W'], list);
      this.sectionList.length = 0;
      this.sectionList = this.inventoryService.getSectionList(cleanList);
      const sorting = (txt1: string, txt2: string) => {
        return txt1.localeCompare(txt2, undefined, {numeric: true, sensitivity: 'base'});
      };
      this.sectionList.sort(sorting);
      this.pristineInventoryObject = {
        planId: p,
        list: cleanList
      };
      this.inventoryList = cleanList;
      if (status && status !== '') {
        this.inventoryList = this.filterByStatus(cleanList, status);
      }
      if ((section) && (section !== '')) {
        this.inventoryList = this.filterBySection(cleanList, section);
      }
      this.inventoryListOnView = this.inventoryList.slice(0, this.itemsPerPage);
    });
  }

  filterByStatus(list: Array<InventoryListModel>, status: string): Array<InventoryListModel> {
    return list.filter((obj) => obj.status === status );
  }

  filterBySection(list: Array<InventoryListModel>, section: string): Array<InventoryListModel> {
    return list.filter((obj) => obj.seat_section === section );
  }

  onSubmitFilter(form: NgForm): void {
    this.currentPage = 1;
    let passPlan;
    let passStatus;
    let passSection;
    const { dirtyPlan, dirtyStatus, dirtySection } = this.getDirtyFilter(form);
    if (dirtyPlan) {
      passPlan = form.value.plan;
      form.controls.plan.markAsUntouched();
      form.controls.plan.markAsPristine();
    }
    if (dirtyStatus) {
      passStatus = form.value.status;
    }
    if (dirtySection) {
      passSection = form.value.section;
    }
    if (form.value.plan === '0') {
      // si seleccionamos "None" en el selector de plan, hacemos un hard reset
      this.restartForm(form);
    } else {
      this.applyFilter(passPlan, passStatus, passSection);

    }
  }

  applyFilter(planId: number, status: string, section: string): void {
    if (planId && status && section) {
      this.init(planId, status, section);
    } else if (planId && section) {
      this.init(planId, null, section);
    } else if (planId && status) {
      this.init(planId, status);
    } else if ((status || status === '') && (section || status === '')) {
      if (status === '' && section ) {
        this.inventoryList = this.filterBySection(this.pristineInventoryObject.list, section);
      } else if (section === '' && status) {
        this.inventoryList = this.filterByStatus(this.pristineInventoryObject.list, status);
      } else if ((section) && (status)) {
        this.inventoryList = this.filterByStatus(this.pristineInventoryObject.list, status);
        this.inventoryList = this.filterBySection(this.inventoryList, section);
      } else if (status === '' && section === '') {
        this.resetTable();
      }
      this.inventoryListOnView = this.inventoryList.slice(0, this.itemsPerPage);
    } else if (status || status === '') {
      if (status === '') {
        this.resetTable();
      } else {
        this.inventoryList = this.filterByStatus(this.pristineInventoryObject.list, status);
        this.inventoryListOnView = this.inventoryList.slice(0, this.itemsPerPage);
      }
    } else if (section || section === '') {
      if (section === '') {
        this.resetTable(); } else {
        this.inventoryList = this.filterBySection(this.pristineInventoryObject.list, section);
        this.inventoryListOnView = this.inventoryList.slice(0, this.itemsPerPage);
      }
    } else if (planId) {
      this.init(planId);
    }
  }

  getDirtyFilter(form: NgForm) {
    const controls = form.controls;
    const resp = {dirtyStatus: false, dirtyPlan: false, dirtySection: false};
    resp.dirtyPlan = controls.plan.dirty;
    resp.dirtyStatus = controls.status.dirty;
    resp.dirtySection = controls.section.dirty;
    return resp;
  }

  restartForm(form: NgForm = null): void {
    this.filter = {
      planId: `0`,
      status: '',
      section: ''
    };
    form.controls.plan.markAsDirty();
    form.controls.section.markAsPristine();
    form.controls.status.markAsPristine();
    this.itemsPerPage = 10;
    this.showToTop = false;
    this.inventoryListOnView.length = 0;
    this.inventoryList.length = 0;
    this.sectionList.length = 0;
    // this.init();
  }

  private resetTable(): void {
    this.currentPage = 1;
    this.inventoryList = this.pristineInventoryObject.list;
    this.inventoryListOnView = this.inventoryList.slice(0, 10);
  }

  pageChanged(event: PageChangedEvent): void {
    const startItem = (event.page - 1) * this.itemsPerPage;
    const endItem = event.page * this.itemsPerPage;
    this.inventoryListOnView = this.inventoryList.slice(startItem, endItem);
  }

  onChangeItemsNumber(target): void {
    const newQuantity = target.target.value;
    this.itemsPerPage = (isNaN(parseInt(newQuantity, 10))) ? this.inventoryList.length : parseInt(newQuantity, 10);
    this.paginationVisible = (newQuantity !== 'MAX');
    this.showToTop = (newQuantity === 'MAX' || newQuantity === '50');
    this.inventoryListOnView = this.inventoryList.slice(0, this.itemsPerPage);
  }

  exportList2CSV(): void {
    const csv = this.toCSV();
    const date = new Date().toLocaleString();
    const file = new File([csv], `inventory_list_${date}.csv`, {type: 'text/plain;charset=utf-8'});
    saveAs(file);
  }

  toCSV(): string {
    let csv = '';
    const separator = ';';
    const headers = `Section${separator}Row${separator}Seat${separator}Locked${separator}Status${separator}Owner${separator}` +
    `Account Number${separator}Price${separator}Price_SCALE${separator}First Name${separator}Last Name\n`;
    csv += headers;
    for (const line of this.inventoryList) {
      const customer = (typeof line.customer !== 'string' && line.customer) ? line.customer
        : {account_name: '', username: '', first_name: '', last_name: ''};
      csv += `${line.seat_section}${separator}${line.seat_row}${separator}${line.seat}${separator}${line.locked}${separator}` +
        `${line.status}${separator}${customer.account_name}${separator}${customer.username}${separator}${line.price}${separator}` +
        `${line.price_type}${separator}${customer.first_name}${separator}${customer.last_name}\n`;
    }
    return csv;
  }

  go2Top(): void {
    document.getElementsByTagName('header')[0].scrollIntoView({behavior: 'smooth'});
  }

  private refreshPage(): void {
    // save current route first
    let currentRoute = this.router.url;
    currentRoute = currentRoute.split('?')[0];
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      this.router.navigate([currentRoute]); // navigate to same route
    });
  }

  replaceLowerBar(text: string) {
    if (text.includes('_')) {
      return text.replace('_', ' ');
    }
    return text;
  }
}
