import { Injectable, Inject } from '@angular/core';
import { DvmService } from '../../shared/services/dvm.service';
import { DVM_CONFIG } from 'src/app/configuration/dvm.configuration';
import { DVMConfiguration } from 'src/app/configuration/dvm-configuration.model';
import { GeneralHandler } from './general.handler';
import { FlowControlService, InventoryService } from '../services';

@Injectable()
export class ClickHandler extends GeneralHandler {

    constructor(@Inject(DVM_CONFIG) private dvmConfig: DVMConfiguration,
                protected dvmService: DvmService,
                private flowService: FlowControlService,
                private inventoryService: InventoryService) {
        super('click', dvmService);
    }

    protected handle(obj): void {
        // Close popovers
        const tooltip: HTMLElement = document.querySelector('#tooltip');
        tooltip.removeAttribute('data-show');

        // Handle
        const viewer = obj.instance;
        const nodes = obj.nodes;
        if (nodes.length) {
            console.log('Click Handler:', nodes);
            if (nodes[0].type === 'section') {
                this.sectionClickHandler(nodes[0]);
            } else if (nodes[0].type === 'seat') {
                this.seatClickHandler(nodes[0]);

            }
        }
    }

    sectionClickHandler(node): void {
        if (node.state === 'selected') {
            this.dvmService.viewer.unselect(node.id);
            this.flowService.setSectionData(null);
        }

        if (node.state === 'available') {
            const nodes = this.dvmService.viewer.getNodesByState('section', 'selected');
            this.dvmService.viewer.unselect(nodes);
            this.dvmService.viewer.select(node.id);
            this.flowService.setSectionData(this.flowService.availabilityBlockmap[node.id]);
        }
    }

    seatClickHandler(node): void {
        // if (this.flowService.viewerMode === 'customer') {
        //     if (node.state !== 'available') {
        //         return;
        //     }
        // }
        // Check if the element is available
        if (node.state === 'available') {
            this.flowService.seatClickId = node.id;
            if (this.flowService.availabilitySeatmap[node.id].hasOwnProperty('id')) {
              if (this.flowService.seatAll) {
                this.flowService.setSeatData(this.flowService.seatAll);
              } else {
                this.flowService.setSeatData(this.flowService.availabilitySeatmap[node.id]);
              }
            } else {
                this.flowService.availabilitySeatmap[node.id].id = node.id;
                if (this.flowService.seatAll) {
                  this.flowService.setSeatData(this.flowService.seatAll);
                } else {
                  this.flowService.setSeatData(this.flowService.availabilitySeatmap[node.id]);
                }
            }
            // Resets the subject flag
            this.inventoryService.errorOnSaveSubject.next(false);


        }
        // Checks if the element is selected
        // const isSuite = (node.id.includes('A') || node.id.includes('B') || node.id.includes('C'));
        const isSuite = false;
        if (node.state === 'selected') {
            if (node.id.includes('TB') || isSuite) {
              this.dvmService.viewer.unselect(Object.keys(this.flowService.availabilitySeatmap));
              delete this.flowService.seatHash[this.flowService.seatAll.id];
            } else {
              this.dvmService.viewer.unselect(node.id);
              delete this.flowService.seatHash[node.id];
            }
            // this.dvmService.viewer.unselect(node.id);
            // delete this.flowService.seatHash[node.id];
            // If there is 1 element on the hash then get's the last element from the hash and sets the info on the side-interface
            if (Object.keys(this.flowService.seatHash).length === 1) {
                this.flowService.setSeatData(this.flowService.availabilitySeatmap[Object.keys(this.flowService.seatHash)[0]]);
            }
            // If there is no element on the hash, then sets the info null to clean the side-interface
            if (Object.keys(this.flowService.seatHash).length === 0) {
                this.flowService.setSeatData(null);
            }
        } else {
            // The element is not selected
            // Selects the element and adds it to the hash
            if (node.id.includes('TB') || isSuite) {
              this.dvmService.viewer.select(Object.keys(this.flowService.availabilitySeatmap));
              this.flowService.seatHash[this.flowService.seatAll.id] = true;

            } else {
              this.dvmService.viewer.select(node.id);
              this.flowService.seatHash[node.id] = true;
            }
            // Selected style for the seat
            const styleSelect: any = {
                none: {
                    fillStyle: 'white',
                    strokeStyle: 'white',
                    fillOpacity: 0.7,
                    lineWidth: 0.2,
                    cursor: 'pointer'
                }
            };
            // (this.dvmService.viewer as any).setNodeCustomStyles(node.id, [styleSelect]);
            // End selected style
        }

        // Send the new length value to the subject
        this.inventoryService.seatsSelectedLengh.next(Object.keys(this.flowService.seatHash).length);
    }

}
