import { Component, OnInit } from '@angular/core';
import { DjangoSessionAuthenticationService } from '../auth';
import { Observable } from 'rxjs';
import { AuthorizationData, BackofficeUser } from '../shared/models';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { NotificationService } from '../services/notification.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  screen: {
    login: boolean,
    forgot: boolean
  };
  loginData: AuthorizationData = {username: '', password: ''};
  user$: Observable<BackofficeUser | false>;
  errors: boolean;
  email: string;

  constructor(private auth: DjangoSessionAuthenticationService,
              private notificationService: NotificationService,
              private router: Router) {
    this.errors = false;
    this.screen = {
      login: true,
      forgot: false
    };
  }

  login() {
    this.auth.login(this.loginData).subscribe(
      (response) => {
          if (response.type === 'sales_person') {
            this.router.navigate(['/inventory/map']);

          } else {
            this.router.navigate(['/home']);
          }
        },
      (err) => { this.showError(err); }
    );

  }

  showError(error): void {
    this.errors = true;
  }

  ngOnInit() {
    // REAL CASE
    // this.user$ = this.auth.getUserLogged$();
    // this.user$.subscribe(user => {
    //   if (user) {
    //   }
    // });
  }

  showForgotPassword(): void {
    this.screen.login = false;
    this.screen.forgot = true;
  }

  backToLogin(): void {
    this.screen.login = true;
    this.screen.forgot = false;
    this.email = '';
  }

  forgotpassword(form: NgForm): void {
    if (form.invalid) {
      return;
    }
    this.auth.passwordReset(form.value.email).subscribe((response) => {
      this.backToLogin();
      this.notificationService.showSuccess('The email was sent');
    }, (err) => {
      console.error(err);
      this.notificationService.showError('Email could not be sent');
    });
  }
}
