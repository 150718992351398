import { Injectable, Inject } from '@angular/core';
import { GeneralHandler } from './general.handler';
import { DVM_CONFIG } from 'src/app/configuration/dvm.configuration';
import { DVMConfiguration } from 'src/app/configuration/dvm-configuration.model';
import { FlowControlService, InventoryService } from '../services';
import { DvmService } from '../../shared/services/dvm.service';
import { APP_CONFIG, BackofficeConfigurationModel } from '../../configuration';

@Injectable()
export class LoadHandler extends GeneralHandler {



    constructor(@Inject(DVM_CONFIG) private dvmConfig: DVMConfiguration,
                @Inject(APP_CONFIG) public appConfig: BackofficeConfigurationModel,
                private flowControl: FlowControlService,
                private inventoryService: InventoryService,
                protected dvmService: DvmService) {
        super('end_load', dvmService);
    }

    protected handle(error, viewer) {
        if (error) { console.log(error); }
        viewer = viewer.instance;
        console.log('Load Handler:', viewer);
        this.flowControl.seatHash = {};
        this.flowControl.seatAll = null; // put null for not show message of sold entirely while loading
        this.inventoryService.seatsSelectedLengh.next(Object.keys(this.flowControl.seatHash).length);
        if (viewer.getMapId() === this.dvmConfig.map_id) {
            this.loadBlockmapHandler(viewer);
        } else {
            this.loadSeatmapHandler(viewer);
        }
    }

    loadBlockmapHandler(viewer) {
        this.dvmService.viewer.selection_area.mouse_options.ctrlKey = false;
        this.flowControl.flowStep = 'blockmap';
        viewer.setAvailability([]);
        this.inventoryService.getPlanInventory(this.flowControl.planSelected).subscribe(
            planAvailability => {
                // viewer.setAvailable('section', this.flowControl.availabilityBlockmap);
                for (const s of Object.keys(planAvailability)) {
                  if (this.appConfig.suitesHash.hasOwnProperty(`${s}(INT)`)) {
                    planAvailability[`${s}(INT)`] = planAvailability[s];
                    delete planAvailability[s];
                  }
                }
                this.flowControl.availabilityBlockmap = planAvailability;
                viewer.setAvailable('section', Object.keys(planAvailability));
            },
            error => {
                console.error(error);
            }
        );
    }

    loadSeatmapHandler(viewer) {
        this.dvmService.viewer.selection_area.mouse_options.ctrlKey = true;
        this.flowControl.flowStep = 'seatmap';
        viewer.setAvailable([]);
        if (this.flowControl.viewerMode === 'inventory') {
            this.inventoryService.getSectionInventory(this.flowControl.planSelected, viewer.getMapId()).subscribe(
                availability => {
                    const section = viewer.getMapId();
                    this.flowControl.availabilitySeatmap = this.inventoryService.getSeatmapAvailability(availability);
                    const filteredAvailability = this.inventoryService.createAvailabilityHashmap(availability);

                    viewer.setAvailable('seat', Object.keys(filteredAvailability.available)
                        .concat(Object.keys(filteredAvailability.locked))
                        .concat(Object.keys(filteredAvailability.owned))
                        .concat(Object.keys(filteredAvailability.reserved))
                        .concat(Object.keys(filteredAvailability.internal_hold))
                        .concat(Object.keys(filteredAvailability.available_internal))
                    );
                    this.inventoryService.setSeatsGroup(filteredAvailability);
                    const seatStyles = this.dvmService.setStyles(viewer.getNodesByType('seat'));
                    for (const seat of Object.keys(seatStyles)) {
                      // viewer.setNodeCustomStyles(seat, [seatStyles[seat]]);
                    }
                    // iterate through availability and see if there is a seat with row = all
                }
            );
        } else if (this.flowControl.viewerMode === 'customer') {
            viewer.addNodesToGroup(viewer.getNodesByState('seat', 'unavailable'), 'customerView');
            this.inventoryService.removeSeatsGroups();
            this.inventoryService.getPlanAvailabilitySection(this.flowControl.planSelected, viewer.getMapId()).subscribe(
                availability => {
                    this.flowControl.availabilitySeatmap = availability;
                    viewer.setAvailable('seat', Object.keys(availability));
                    const seatStyles = this.dvmService.setStyles(viewer.getNodesByType('seat'));
                    for (const seat of Object.keys(seatStyles)) {
                        // viewer.setNodeCustomStyles(seat, [seatStyles[seat]]);
                    }
                }
            );
        }

    }

}
