import { Component, OnInit, Input } from '@angular/core';
import { TransactionService } from '../services';
import { TransactionModel, TransactionActionsModel } from '../shared';
import { PlanModel, SeasonModel } from '../../shared';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-transaction-detail',
  templateUrl: './transaction-detail.component.html',
  styleUrls: ['./transaction-detail.component.scss']
})
export class TransactionDetailComponent implements OnInit {

  transactionID: any;
  transaction: TransactionModel;
  // tslint:disable-next-line:variable-name
  transaction_related: TransactionModel;
  customer;
  plan: PlanModel;
  season: SeasonModel;
  transactionSeats;
  keptSeats: Array<any>;
  removedSeats: Array<any>;
  addedSeats: Array<any>;
  seatactions: TransactionActionsModel;
  // tslint:disable-next-line:variable-name
  seatactions_related: TransactionActionsModel;
  isUpgrade: boolean;


  constructor(private transactionService: TransactionService, private route: ActivatedRoute) {
    this.seatactions = {} as TransactionActionsModel;
    this.seatactions_related = {} as TransactionActionsModel;
    this.keptSeats = [];
    this.addedSeats = [];
    this.removedSeats = [];
    this.isUpgrade = false;
  }

  ngOnInit() {
    this.init();
  }

  private init(): void {
    this.transactionID = this.route.snapshot.paramMap.get('id');

    this.transactionService.getTransactionById(this.transactionID).subscribe(
      transaction => {
        this.transaction = transaction;
        this.transaction.status = this.transactionService.statusTranslate(this.transaction.status);
        this.customer = transaction.customer;
        this.plan = transaction.plan;
        this.season = transaction.season;
        // get related transaction if exist
        if (transaction.related) {
          this.isUpgrade = true;
          this.transactionService.getTransactionById(transaction.related).subscribe(
            related => {
              this.transaction_related = related;
            }
          );
          this.transactionService.getSeatTransactions(this.transaction.related).subscribe(
            seats => {
              this.seatactions_related = this.filterAction(seats);
            }
          );
        }
      }
    );

    this.transactionService.getSeatTransactions(this.transactionID).subscribe(
      seats => {
        this.transactionSeats = seats;
        this.seatactions = this.filterAction(this.transactionSeats);
      }
    );
  }

  private filterAction(seatList: Array<any>): TransactionActionsModel {
    const actionsObj = {} as TransactionActionsModel;
    seatList.forEach(
      (seatTransaction) => {
        if (!actionsObj.hasOwnProperty(seatTransaction.action)) {
          actionsObj[seatTransaction.action] = [];
        }
        actionsObj[seatTransaction.action].push(seatTransaction);
      });
    return actionsObj;
  }

  isRefund(price: number): boolean {
    let refund;
    (price >= 1) ? refund = false : refund = true;
    return refund;
  }

  formatPrice(price: number): string {
    // tslint:disable-next-line:variable-name
    const _price = Math.abs(price);
    return _price.toLocaleString('en-US', {maximumFractionDigits: 2});
  }

  isDisabled(array: Array<any>): boolean {
    return array.length !== 0;
  }

}
